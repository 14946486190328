import React from "react";
import { MDXProvider } from "@mdx-js/react";
import shortcodes from "./mdxComponents";

export default function Content({ children }) {
  return (
    <div className="flex bg-deep-black">
      <div className="content type-news">
        <MDXProvider components={shortcodes}>
          {/* className="w-2/4" */}
          {children}
        </MDXProvider>
      </div>
    </div>
  );
}
